/**
 * Main JS
 */
(function($) {

  $(function() {



  });//end DOM loaded

})(jQuery); // Fully reference jQuery after this point.
